require('./bootstrap');
require('lightbox2/dist/js/lightbox-plus-jquery.min');
require('lightbox2/dist/css/lightbox.min.css');
require('bootstrap-datepicker')
// function getImage(offset = 0) {
//     axios
//         .get(`/checkAllUrlImage/${offset}`)
//         .then(resp => {
//             console.log(resp.data)
//             getImage(offset + 5)
//         })
//         .catch(error => {
//             console.error(error)
//         })
// }
$(document).ready(
    function () {
        $('.slick-single-oferta').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: false,
            arrows: false,
            adaptiveHeight: true,
            infinite: true,
            useTransform: true,
            cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
            asNavFor: '.slick-carousel-oferta',
        });
        $('.slick-carousel-oferta').slick({
            dots: false,
            infinite: true,
            speed: 300,
            asNavFor: '.slick-single-oferta',
            slidesToShow: 4,
            arrows: true,
            focusOnSelect: true,
            slidesToScroll: 4,
            prevArrow: '<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">\n' +
                '                            <i class="zmdi zmdi-chevron-left"></i>\n' +
                '                            <span class="visually-hidden">Previous</span>\n' +
                '                        </button>',
            nextArrow: '<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">\n' +
                '                            <i class="zmdi zmdi-chevron-right"></i>\n' +
                '                            <span class="visually-hidden">Next</span>\n' +
                '                        </button>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        })

        $(document).ready(function() {
            $('.card-slider').slick({
                dots: false,
                arrows: true,
                slidesToShow: 4,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
        });

        $("#occupy, .passengers-dropdown, .citybreak-occupy").click(
            function(){
                $(this).next(".adult-copi-camera").slideToggle();
                $(this).next(".adult-copi-camera-hotel").slideToggle();
            }
        );
        // $(".adult-copi-camera-hotel .zmdi-minus").click(
        //     function(){
        //
        //     }
        // )
        // $(".adult-copi-camera .zmdi-minus").click(
        //     function(){
        //         var totalPsnger = 0;
        //         if($(this).next('input').val() !== ''){
        //
        //             if($(this).next('input').val() <= 0){
        //                 $(this).next('input').val(0);
        //             }
        //             else{
        //                 var minus1          =       parseInt($(this).next('input').val()) - parseInt(1);
        //                 $(this).next('input').val(minus1);
        //
        //                 if($(this).closest('.adult-field').first().length > 0){
        //                     $(this).closest('label[for="occupy"]').find(".adult-value").html(minus1);
        //                     $(this).closest('label[for="occupy"]').find('input[name="adult"]').val(minus1);
        //                 }
        //                 else if($(this).closest('.copi-field').first().length > 0){
        //                     $(this).closest('label[for="occupy"]').find(".copi-value").html(minus1);
        //                     $(this).closest('label[for="occupy"]').find('input[name="copi"]').val(minus1);
        //                 }
        //                 else if($(this).closest('.camera-field').first().length > 0){
        //                     $(this).closest('label[for="occupy"]').find(".camera-value").html(minus1);
        //                     $(this).closest('label[for="occupy"]').find('input[name="camera"]').val(minus1);
        //                 }
        //                 if($(this).closest('label[for="occupy"]').find('.totalPsnger').length > 0){
        //                     totalPsnger     = $(this).closest('label[for="occupy"]').find('input[name="adult"]').val();
        //                     totalPsnger     = parseInt($(this).closest('label[for="occupy"]').find('input[name="copi"]').val()) + parseInt(totalPsnger);
        //                     totalPsnger     = parseInt($(this).closest('label[for="occupy"]').find('input[name="camera"]').val()) + parseInt(totalPsnger);
        //                     $(this).closest('label[for="occupy"]').find('.totalPsnger').html(totalPsnger);
        //                 }
        //
        //             }
        //         }else{
        //             $(this).next('input').val(0);
        //
        //             if($(this).closest('.adult-field').first().length > 0){
        //                 $(this).closest('label[for="occupy"]').find(".adult-value").html(1);
        //                 $(this).closest('label[for="occupy"]').find('input[name="adult"]').val(1);
        //             }
        //             else if($(this).closest('.copi-field').first().length > 0){
        //                 $(this).closest('label[for="occupy"]').find(".copi-value").html(0);
        //                 $(this).closest('label[for="occupy"]').find('input[name="copi"]').val(0);
        //             }
        //             else if($(this).closest('.camera-field').first().length > 0){
        //                 $(this).closest('label[for="occupy"]').find(".camera-value").html(1);
        //                 $(this).closest('label[for="occupy"]').find('input[name="camera"]').val(1);
        //             }
        //             if($(this).closest('label[for="occupy"]').find('.totalPsnger').length > 0){
        //                 $(this).closest('label[for="occupy"]').find('.totalPsnger').html(1);
        //             }
        //         }
        //     }
        // );
        // $(".adult-copi-camera .zmdi-plus").click(
        //     function(){
        //         var totalPsnger = 0;
        //         if($(this).prev('input').val() !== ''){
        //             var plus1          =       parseInt($(this).prev('input').val()) + parseInt(1);
        //             $(this).prev('input').val(plus1);
        //
        //             if($(this).closest('.adult-field').first().length > 0){
        //                 $(this).closest('label[for="occupy"]').find(".adult-value").html(plus1);
        //                 $(this).closest('label[for="occupy"]').find('input[name="adult"]').val(plus1);
        //             }
        //             else if($(this).closest('.copi-field').first().length > 0){
        //                 $(this).closest('label[for="occupy"]').find(".copi-value").html(plus1);
        //                 $(this).closest('label[for="occupy"]').find('input[name="copi"]').val(plus1);
        //             }
        //             else if($(this).closest('.camera-field').first().length > 0){
        //                 $(this).closest('label[for="occupy"]').find(".camera-value").html(plus1);
        //                 $(this).closest('label[for="occupy"]').find('input[name="camera"]').val(plus1);
        //             }
        //             if($(this).closest('label[for="occupy"]').find('.totalPsnger').length > 0){
        //                 totalPsnger    = $(this).closest('label[for="occupy"]').find('input[name="adult"]').val();
        //                 totalPsnger    =  parseInt($(this).closest('label[for="occupy"]').find('input[name="copi"]').val()) + parseInt(totalPsnger);
        //                 totalPsnger    = parseInt($(this).closest('label[for="occupy"]').find('input[name="camera"]').val()) + parseInt(totalPsnger);
        //                 $(this).closest('label[for="occupy"]').find('.totalPsnger').html(totalPsnger);
        //             }
        //         }else{
        //             $(this).prev('input').val(1);
        //             if($(this).closest('.adult-field').first().length > 0){
        //                 $(this).closest('label[for="occupy"]').find(".adult-value").html(1);
        //                 $(this).closest('label[for="occupy"]').find('input[name="adult"]').val(1);
        //             }
        //             else if($(this).closest('.copi-field').first().length > 0){
        //                 $(this).closest('label[for="occupy"]').find(".copi-value").html(1);
        //                 $(this).closest('label[for="occupy"]').find('input[name="copi"]').val(1);
        //             }
        //             else if($(this).closest('.camera-field').first().length > 0){
        //                 $(this).closest('label[for="occupy"]').find(".camera-value").html(1);
        //                 $(this).closest('label[for="occupy"]').find('input[name="camera"]').val(1);
        //             }
        //             if($(this).closest('label[for="occupy"]').find('.totalPsnger').length > 0){
        //                 $(this).closest('label[for="occupy"]').find('.totalPsnger').html(1);
        //             }
        //         }
        //     }
        // );
        // $(".adult-field input").on('change', function(){
        //     $(this).closest('label[for="occupy"]').find(".adult-value").html($(this).val());
        //     $('input[name="adult"]').val($(this).val());
        // });
        // $(".copi-field input").on('change', function(){
        //     $(this).closest('label[for="occupy"]').find(".copi-value").html($(this).val());
        //     $('input[name="copi"]').val($(this).val());
        // });
        // $(".camera-field input").on('change', function(){
        //     $(this).closest('label[for="occupy"]').find(".camera-value").html($(this).val());
        //     $('input[name="camera"]').val($(this).val());
        // });
        $(".datepickerClass").datepicker({
            dateFormat: 'dd/mm/yy',
            minDate: 0,
            onClose: function() {
                var jsDate =$(this).datepicker('getDate');
                if (jsDate !== null) {
                    $(this).next('span').addClass('focused-input');
                }
                else{
                    $(this).next('span').removeClass('focused-input');
                }
            }
        });

        $(".filteration-by-date-price").click(
            function(){
                $(".filteration-by-date-price-content").slideToggle();
            }
        );

        syncFilter();

        // function filterMaiMulte(){
        //     var countFilter         =   0;

        //     $(".sidebar-filter-widget ul").each(
        //         function(){
        //             var countFilter     =   $(this).find('li').length;
        //             var stopper         =   0;
        //             $(this).find('li').each(
        //                 function(){
        //                     if(stopper > 5){
        //                         $(this).slideUp();
        //                         if($(this).closest('ul').first().find(".mai-multe").length > 0){
        //                             $(this).closest('ul').first().find(".mai-multe").slideDown();
        //                         }else{
        //                             $(this).closest('ul').first().append('<li class="mai-multe">Mai multe <i class="zmdi zmdi-chevron-down"></i></li>');
        //                         }
        //                     }
        //                     stopper++;
        //                 }
        //             );
        //         }
        //     );
        // }
        // if($(".sidebar-filter-widget").length > 0){
        //     filterMaiMulte();

        //     $(document).on('click', '.mai-multe', function(){
        //         $(this).closest('ul').first().find('li').slideDown();
        //         // if (window.innerWidth <= 768) {
        //             $(this).replaceWith('<li class="up-mai-multe">Mai putine <i class="zmdi zmdi-chevron-up"></i></li>');
        //         // }
        //     });
        //     $(document).on('click', '.up-mai-multe', function(){
        //         var stopper = 0;
        //         $(this).closest('ul').first().find('li').each(
        //             function(){
        //                 if(stopper > 5){
        //                     $(this).slideUp();
        //                 }
        //                 stopper++;
        //             }
        //         );
        //         $(this).replaceWith('<li class="mai-multe">Mai multe <i class="zmdi zmdi-chevron-down"></i></li>');
        //     });
        //     $(".sidebar-filter-widget .group-heading").click(
        //         function(){
        //             if (window.innerWidth <= 768) {
        //                 $(".sidebar-filter-content").slideToggle();
        //             }
        //         }
        //     );
        //     $(".car-sidebar-filter-content p").click(
        //         function(){
        //             $(this).next(".car-sidebar-filter-content ul").slideToggle();
        //         }
        //     );
        // }
    }
)


window.onload = (event) => {
    $('#divImages > #loading').addClass('d-none')
    $('.carousel-div-oferta').removeClass('d-none')
    $('.carousel-div-albume').removeClass('d-none')

    $(".se-pre-con").fadeOut("slow");
    $('body').css('overflow', 'auto');
}

